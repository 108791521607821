
import Vue from "vue";
import Component from "vue-class-component";
import { mdiDelete } from "@mdi/js";

@Component({})
export default class MultiFileSelect extends Vue {
  private files: Array<File> = [];

  deleteFile(file: File) {
    const index = this.files.indexOf(file);

    if (index < 0) {
      return;
    }

    this.files.splice(index, 1);

    this.$emit("input", this.files);
  }

  fileSelected(event: Event) {
    if (!event.target) {
      return;
    }
    const files = (event.target as HTMLInputElement).files;
    if (!files) {
      return;
    }

    Array.from(files).forEach(file => {
      if (this.files.indexOf(file) < 0) {
        this.files.push(file);
      }
    });

    this.$emit("input", this.files);
  }

  /**
   * Only there to make a nice hover background.
   */
  blackhole() {}

  // ICONS
  private deleteIcon = mdiDelete;
}
