
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { UserToAdd, Users } from "@/components/users/Users";

@Component
export default class UserModificationComponent extends Vue {
  private formValid = true;

  @Prop()
  private users!: Users;

  @Prop()
  private editing!: boolean;

  @Prop({ default: () => new UserToAdd("", "", [], "") })
  private user!: UserToAdd;

  get roleString() {
    return this.user.roles.join("\n");
  }

  set roleString(input: string) {
    // filter blank lines
    this.user.roles = input.split("\n").filter(it => it.length > 0);
  }

  public clear() {
    this.user = new UserToAdd("", "", [], "");
  }

  idRule(input: string) {
    if (this.editing) {
      return true;
    }
    return this.users.users.some(it => it.id == input)
      ? "A user with that id exists already!"
      : true;
  }

  emitUser() {
    this.$emit("user", this.user);
  }
}
