
import { Component, Vue, Watch } from "vue-property-decorator";
import NavigationBar from "./components/NavigationBar.vue";
import { Store } from "vuex";
import { RootState } from "./store/types";
import ThemeSelector from "./components/ThemeSelector.vue";

@Component({
  components: {
    "navigation-bar": NavigationBar,
    "theme-selector": ThemeSelector
  }
})
export default class App extends Vue {
  private actionsHidden = false;

  handleNavBarActionsVisibilityChange(hidden: boolean) {
    this.actionsHidden = hidden;
  }

  created() {
    this.$vuetify.theme.dark = this.isDarkTheme;
  }

  private get typedStore() {
    return this.$store as Store<RootState>;
  }

  private setDarkTheme(darkTheme: boolean) {
    this.typedStore.commit("user/darkThemeSelected", darkTheme);
  }

  @Watch("isDarkTheme")
  private onDarkThemeChanged() {
    this.$vuetify.theme.dark = this.isDarkTheme;
  }

  private get isDarkTheme() {
    return this.typedStore.state.user.darkThemeSelected();
  }
}
