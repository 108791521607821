
import Vue from "vue";
import Component from "vue-class-component";
import { mdiDelete, mdiPlusCircle } from "@mdi/js";
import { IOCheckFile } from "../checklist/CheckTypes";
import { Prop } from "vue-property-decorator";

@Component
export default class TextfieldFileAddComponent extends Vue {
  @Prop({ default: true })
  editable!: boolean;

  @Prop({ default: () => [] })
  value!: Array<IOCheckFile>;

  @Prop({ default: false })
  plainOutline!: boolean;

  @Prop({ default: "Datei Hinzufügen" })
  fileUploadPrompt!: string;

  deleteFile(file: IOCheckFile) {
    this.$emit(
      "input",
      this.value.filter(it => it != file)
    );
  }

  addNew() {
    const newFiles = this.value.slice();
    newFiles.push(new IOCheckFile("", ""));
    this.$emit("input", newFiles);
  }

  // ICONS
  private deleteIcon = mdiDelete;
  private addIcon = mdiPlusCircle;
}
