
import Vue from "vue";
import Component from "vue-class-component";
import { Model, Prop } from "vue-property-decorator";
import { PrismEditor } from "vue-prism-editor";
import { guessLineType, highlight } from "@/util/Highlighting";
import "vue-prism-editor/dist/prismeditor.min.css"; // import the styles somewhere
import { IoLine } from "@/store/types";

@Component({
  components: {
    "prism-editor": PrismEditor
  }
})
export default class HighlightedCode extends Vue {
  @Model("input", { default: "" })
  private readonly code!: string;

  @Prop({ default: () => [] })
  private readonly ioLines!: IoLine[];

  @Prop({ default: false, type: Boolean })
  private readonly readonly!: boolean;

  private get effectiveCode() {
    if (this.ioLines.length > 0) {
      return this.ioLines.map(it => it.content).join("\n");
    }
    return this.code;
  }

  private highlight(code: string) {
    if (this.ioLines.length > 0) {
      return highlight(this.ioLines);
    }
    return highlight(
      code.split("\n").map(it => new IoLine(guessLineType(it), it))
    );
  }

  private mounted() {
    Array.from(this.$el.getElementsByTagName("textarea")).forEach(area => {
      area.placeholder = "> Some Input\nSome output";
    });
  }
}
