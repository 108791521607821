
import Vue from "vue";
import Component from "vue-class-component";
import { mdiInvertColors } from "@mdi/js";
import { Store } from "vuex";
import { RootState } from "../store/types";

@Component
export default class ThemeSelector extends Vue {
  private toggleDarkTheme() {
    this.$emit("useDarkTheme", !this.isDarkTheme);
  }

  private get isDarkTheme(): boolean {
    return (this.$store as Store<RootState>).state.user.darkThemeSelected();
  }

  // ICONS
  private darkThemeIcon = mdiInvertColors;
}
