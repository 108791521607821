
import Vue from "vue";
import Component from "vue-class-component";
import { CheckCategory, RootState } from "@/store/types";
import { Store } from "vuex";
import { extractErrorMessage } from "@/util/requests";
import { Watch } from "vue-property-decorator";
import { mdiDelete, mdiMagnify, mdiPencil } from "@mdi/js";

@Component
export default class CheckCategoryList extends Vue {
  private message: string = "";
  private messageType: string = "error";
  private search: string = "";
  private footerProps = {
    itemsPerPageOptions: [4, 10, 20, 50, 100]
  };
  private currentCheckName = "";
  private addDialogOpened = false;
  private ipp = 40;

  get headers() {
    if (this.isAdmin) {
      return [
        { text: "Name", value: "name", align: "center" },
        { text: "Id", value: "id", align: "center" },
        { text: "Actions", value: "actions", align: "center" }
      ];
    }
    return [
      { text: "Name", value: "name", align: "center" },
      { text: "Id", value: "id", align: "center" }
    ];
  }

  get categories(): Array<CheckCategory> {
    return (this.$store as Store<RootState>).state.checkcategory.categories;
  }

  get isAdmin() {
    return (this.$store as Store<RootState>).state.user.isAdmin();
  }

  get canSubmit() {
    return this.addDialogOpened && this.currentCheckName.length > 0;
  }

  get rowsPerPage(): number {
    return (this.$store.state as RootState).miscsettings.itemsPerPage;
  }

  @Watch("footerProps.pagination.rowsPerPage", { deep: true })
  setRowsPerPage(rows: number) {
    this.$store.commit("miscsettings/setItemsPerPage", rows);
  }

  addCategory() {
    this.addDialogOpened = false;
    this.handlePromise(
      this.$store
        .dispatch("checkcategory/addNew", this.currentCheckName)
        .then(it => {
          this.message = "The check category was added with id " + it.id;
          this.messageType = "success";
        })
    );
    this.currentCheckName = "";
  }

  deleteCheckCategory(category: CheckCategory) {
    const confirmDelete = confirm(
      `Do you really want to delete '${category.name}' (${category.id})`
    );

    if (!confirmDelete) {
      return;
    }
    this.handlePromise(
      this.$store
        .dispatch("checkcategory/deleteCheckCategory", category)
        .then(() => {
          this.message = "";
        })
    );
  }

  renameCategory(category: CheckCategory, name: string) {
    console.log(category);

    this.handlePromise(
      this.$store
        .dispatch("checkcategory/renameCheckCategory", {
          id: category.id,
          newName: name
        })
        .then(() => (this.message = ""))
    );
  }

  handlePromise(promise: Promise<any>) {
    promise.catch(error => {
      this.message = extractErrorMessage(error);
      this.messageType = "error";
    });
  }

  mounted() {
    this.handlePromise(this.$store.dispatch("checkcategory/fetchAll"));
  }

  // ICONS
  private deleteIcon = mdiDelete;
  private searchIcon = mdiMagnify;
  private editIcon = mdiPencil;
}
