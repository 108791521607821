import { render, staticRenderFns } from "./CheckCategorySelection.vue?vue&type=template&id=328a27e1&scoped=true&"
import script from "./CheckCategorySelection.vue?vue&type=script&lang=ts&"
export * from "./CheckCategorySelection.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "328a27e1",
  null
  
)

export default component.exports