
import Vue from "vue";
import { RootState } from "@/store/types";
import { Store } from "vuex";
import Component from "vue-class-component";
import { mdiCloudUpload, mdiPlusCircleOutline } from "@mdi/js";

@Component
export default class Profile extends Vue {
  private headers = [
    { text: "Name", value: "name", align: "center" },
    { text: "Value", value: "value", align: "center" }
  ];

  get store(): Store<RootState> {
    return this.$store as Store<RootState>;
  }
  get userName() {
    return this.store.state.user.userName;
  }
  get displayName() {
    return this.store.state.user.displayName;
  }

  get items() {
    return [
      {
        title: "ID",
        value: this.store.state.user.userName
      },
      {
        title: "Roles",
        value: this.store.state.user.roles.join(", ")
      }
    ];
  }

  // Icons
  private checkCodeIcon = mdiCloudUpload;
  private submitCheckIcon = mdiPlusCircleOutline;
}
