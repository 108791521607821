import { render, staticRenderFns } from "./CrudModifyActions.vue?vue&type=template&id=3535ff85&scoped=true&"
import script from "./CrudModifyActions.vue?vue&type=script&lang=ts&"
export * from "./CrudModifyActions.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3535ff85",
  null
  
)

export default component.exports