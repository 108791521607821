
import Vue from "vue";
import { Store } from "vuex";
import { RootState } from "@/store/types";
import { Route } from "vue-router";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import {
  mdiAccount,
  mdiAccountMultiple,
  mdiExitToApp,
  mdiFormatListBulleted,
  mdiHistory,
  mdiLock,
  mdiPlusCircleOutline,
  mdiShape,
  mdiStarHalfFull,
  mdiDiscord
} from "@mdi/js";

@Component({})
export default class NavigationBar extends Vue {
  title = "Simple Code Tester";
  drawer = false;

  // ================ Icons ====================
  private addCheckIcon = mdiPlusCircleOutline;
  private checkCodeIcon = mdiStarHalfFull;
  private profileIcon = mdiAccount;
  private lastCheckResultIcon = mdiHistory;
  private allChecksIcon = mdiFormatListBulleted;
  private checkCategoryIcon = mdiShape;
  private usersIcon = mdiAccountMultiple;
  private changePasswordIcon = mdiLock;
  private logoutIcon = mdiExitToApp;
  private discordIcon = mdiDiscord;
  // ===========================================

  navigationItems = [
    {
      icon: this.profileIcon,
      title: "Profile",
      route: "/profile",
      predicatePath: "profile"
    },
    {
      icon: this.checkCodeIcon,
      title: "Check Code",
      route: "/check-code",
      predicatePath: "checkCode"
    },
    {
      icon: this.lastCheckResultIcon,
      title: "View last check result",
      route: "/view-check-result",
      predicatePath: "viewCheckResult",
      statefulIf: function(state: RootState): boolean {
        return state.checkresult.checkResult !== null;
      }
    },
    {
      icon: this.addCheckIcon,
      title: "Submit check",
      route: "/submit-check",
      predicatePath: "submitCheck"
    },
    {
      icon: this.allChecksIcon,
      title: "All checks",
      route: "/view-checks",
      predicatePath: "viewChecks"
    },
    {
      icon: this.checkCategoryIcon,
      title: "Manage check categories",
      route: "/view-check-categories",
      predicatePath: "viewCheckCategories",
      admin: true
    },
    {
      icon: this.usersIcon,
      title: "Manage Users",
      route: "/view-users",
      predicatePath: "viewUsers",
      admin: true
    },
    {
      icon: this.changePasswordIcon,
      title: "Change password",
      route: "/change-own-password",
      predicatePath: "changePassword"
    },
    {
      icon: this.logoutIcon,
      title: "Logout",
      route: "/logout",
      predicatePath: "login"
    }
  ];

  @Prop()
  actionsHidden!: Boolean;

  get currentRoute(): Route {
    return this.$route;
  }

  get applicableItems(): Array<any> {
    return this.navigationItems.filter(it => {
      // only for admins and you are none
      if (it.admin && it.admin && !this.isAdmin) {
        return false;
      }
      // does not have further restrictions
      if (!it.statefulIf) {
        return true;
      }
      return it.statefulIf(this.$store.state);
    });
  }

  get isAdmin(): boolean {
    const userState = (this.$store as Store<RootState>).state.user;
    return userState.roles && userState.roles.some(it => it === "ROLE_ADMIN");
  }

  logout() {
    this.$store.dispatch("logout");
    this.$router.push("/login");
  }

  navigate(item: any) {
    if (item.title === "Logout") {
      this.logout();
    } else {
      this.$router.push(item.route);
    }
  }
}
