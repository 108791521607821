
import Vue from "vue";
import Component from "vue-class-component";
import { Store } from "vuex";
import {
  CheckResultType,
  FileCheckResult,
  IoLine,
  IoLineType,
  RootState
} from "@/store/types";
import { mdiCheckCircleOutline, mdiCloseCircleOutline } from "@mdi/js";
import TextfieldFileAddComponent from "./checksubmit/TextfieldFileAddComponent.vue";
import HighlightedCode from "./highlighting/HighlightedCode.vue";

class SingleFileResult {
  fileName: string;
  results: Array<FileCheckResult>;
  successful: boolean;
  totalDuration: number;
  averageDuration: number;

  constructor(
    fileName: string,
    results: Array<FileCheckResult>,
    successful: boolean
  ) {
    this.fileName = fileName;
    this.results = results;
    this.successful = successful;

    this.totalDuration = this.results
      .map(it => it.durationMillis || 0)
      .reduce((acc, next) => acc + next);
    this.averageDuration = this.totalDuration / this.results.length;

    // Pull up failed checks, sort alphabetically inside the groups
    this.results.sort((a, b) => {
      if (
        a.result !== CheckResultType.FAILED &&
        b.result === CheckResultType.FAILED
      ) {
        return 1;
      } else if (
        a.result === CheckResultType.FAILED &&
        b.result !== CheckResultType.FAILED
      ) {
        return -1;
      }
      return a.check.localeCompare(b.check);
    });
  }
}

@Component({
  components: {
    "display-files-component": TextfieldFileAddComponent,
    "highlighted-code": HighlightedCode
  }
})
export default class Test extends Vue {
  private items: Array<SingleFileResult> = [];
  private showTimes: boolean = false;

  get allPassed() {
    return this.items.every(elem => elem.successful);
  }

  get failCount() {
    return this.items.filter(elem => !elem.successful).length;
  }

  get failureBooleanArray() {
    return Array.from(this.items)
      .slice()
      .map(it => !it.successful);
  }
  // We just ignore it, as we  don't need it
  set failureBooleanArray(array: Array<boolean>) {}

  findButtonOnCopy(element: HTMLElement) {
    if (element.tagName !== "BUTTON") {
      return element.parentElement!!;
    }
    return element;
  }

  private get shouldHideTimesBecausePeopleAreNaughtyAndCompareThem(): boolean {
    if (this.items.length === 0) {
      return true;
    }
    return (
      this.items.map(it => it.totalDuration).reduce((a, b) => a + b) < 15 * 1000
    );
  }

  private onShowTimes() {
    const promptMessage =
      "Only DESIGN and FUNCTIONALITY are relevant for this module.\n\n" +
      "Please DO NOT OPTIMIZE your code for performance and DO NOT COMPARE your times with others.\n\n" +
      "These times are just here to help you debug timeouts.";

    if (window.confirm(promptMessage)) {
      this.showTimes = true;
    }
  }

  private fudgeDuration(millis: number) {
    if (millis < 30) {
      return "<30";
    }
    return Math.ceil(millis / 20) * 20;
  }

  copyFullInput(result: FileCheckResult, element: HTMLElement) {
    let input = result.output
      .filter(line => line.lineType === IoLineType.INPUT)
      .map(line => line.content)
      .map(line => line.replace(/^> /, ""))
      .join("\n");
    this.copyText(input, element);
  }

  copyInputUntilError(result: FileCheckResult, element: HTMLElement) {
    let linesUntilError: Array<IoLine> = [];

    for (let i = 0; i < result.output.length; i++) {
      if (result.output[i].lineType === IoLineType.ERROR) {
        break;
      }
      linesUntilError.push(result.output[i]);
    }
    // do not include the output producing the error
    linesUntilError.pop();

    let input = linesUntilError
      .filter(line => line.lineType === IoLineType.INPUT)
      .map(line => line.content)
      .map(line => line.replace(/^> /, ""))
      .join("\n");
    this.copyText(input, element);
  }

  copyFullOutput(result: FileCheckResult, element: HTMLElement) {
    this.copyText(result.output.map(line => line.content).join("\n"), element);
  }

  private copyText(text: string, element: HTMLElement) {
    let textArea = document.createElement("textarea") as HTMLTextAreaElement;
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      let successful = document.execCommand("copy");
      if (successful) {
        element.classList.add("flash-green");
        setTimeout(() => {
          element.classList.remove("flash-green");
        }, 400);
      }
    } finally {
      document.body.removeChild(textArea);
    }
  }

  mounted() {
    const checkResult = (this.$store as Store<RootState>).state.checkresult
      .checkResult;

    if (!checkResult) {
      return;
    }

    checkResult.results.forEach(({ key, value }) => {
      const allSuccessful = value.every(
        elem => elem.result !== CheckResultType.FAILED
      );

      this.items.push(new SingleFileResult(key, value.slice(), allSuccessful));
    });

    // Pull up failed checks, sort alphabetically inside the groups
    this.items.sort((a, b) => {
      if (a.successful && !b.successful) {
        return 1;
      } else if (!a.successful && b.successful) {
        return -1;
      }
      return a.fileName.localeCompare(b.fileName);
    });
  }

  // ICONS
  private successfulIcon = mdiCheckCircleOutline;
  private failedIcon = mdiCloseCircleOutline;
}
