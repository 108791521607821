
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class CheckSubmitErrorDialog extends Vue {
  @Prop()
  private problems!: Array<string>;

  @Prop()
  private displayed!: boolean;

  blackhole() {}
}
