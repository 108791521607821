
import Vue from "vue";
import Component from "vue-class-component";
import { CheckBase } from "@/components/checklist/CheckTypes";
import { Prop } from "vue-property-decorator";
import { IoLine, IoLineType } from "@/store/types";
import TextfieldFileAddComponent from "../checksubmit/TextfieldFileAddComponent.vue";
import HighlightedCode from "../highlighting/HighlightedCode.vue";

@Component({
  components: {
    "highlighted-code": HighlightedCode,
    "display-files-component": TextfieldFileAddComponent
  }
})
export default class CheckDisplay extends Vue {
  @Prop()
  private checkBase!: CheckBase;

  @Prop()
  private content!: any;

  get interleavedLines() {
    let lines = (this.content.check.input as string).split(/\n/);

    return lines.map(line => {
      if (line.startsWith("> ")) {
        return new IoLine(IoLineType.INPUT, line);
      } else if (line.startsWith("<")) {
        return new IoLine(IoLineType.OUTPUT, line);
      } else if (line.startsWith("#")) {
        return new IoLine(IoLineType.OTHER, line);
      } else if (line.startsWith("$$")) {
        return new IoLine(IoLineType.PARAMETER, line);
      } else {
        return new IoLine(IoLineType.OUTPUT, line);
      }
    });
  }
}
