
import Vue from "vue";
import Component from "vue-class-component";
import { Store } from "vuex";
import { CheckCategory, RootState } from "@/store/types";
import { extractErrorMessage } from "@/util/requests";
import ModifyActions from "@/components/checklist/CheckModifyActions.vue";
import { CheckBase, CheckCollection } from "@/components/checklist/CheckTypes";
import CheckDisplay from "@/components/checklist/CheckDisplay.vue";
import { Watch } from "vue-property-decorator";
import { mdiFilterPlusOutline, mdiMagnify } from "@mdi/js";

@Component({
  components: {
    "modify-actions": ModifyActions,
    "check-display": CheckDisplay
  }
})
export default class CheckList extends Vue {
  private checks: CheckCollection = new CheckCollection();
  private selectedCategories: CheckCategory[] = [];
  private error: string = "";
  private search: string = "";
  private footerProps = {
    itemsPerPageOptions: [4, 10, 20, 50, 100],
    pagination: {
      rowsPerPage: this.rowsPerPage
    }
  };
  // needed to reset open expansion panels on page navigation
  private openedItems: number[] = [];

  get rowsPerPage(): number {
    return (this.$store.state as RootState).miscsettings.itemsPerPage;
  }

  @Watch("footerProps.pagination.rowsPerPage", { deep: true })
  setRowsPerPage(rows: number) {
    this.$store.commit("miscsettings/setItemsPerPage", rows);
  }

  get userState() {
    return (this.$store as Store<RootState>).state.user;
  }

  setError(error: string) {
    this.error = error;
  }

  private get allCategories() {
    return (this.$store.state as RootState).checkcategory.categories
      .slice()
      .sort((a, b) => b.id - a.id);
  }

  private get filteredChecks() {
    if (this.selectedCategories.length === 0) {
      return this.checks.getCheckBases();
    }
    const allowedCategories = new Set(this.selectedCategories.map(it => it.id));
    return this.checks
      .getCheckBases()
      .filter(it => allowedCategories.has(it.category.id));
  }

  fetchCheckText(checkBase: CheckBase) {
    this.checks
      .fetchContent(checkBase)
      .then(() => this.setError(""))
      .catch(error => this.setError(extractErrorMessage(error)));
  }

  filterHandlingApproved(items: any[], search: string): any[] {
    return items.filter(val => {
      return this.checkMatchesSearch(val, search);
    });
  }

  private containsWordsInAnyOrder(needle: string, haystack: string): boolean {
    const words = needle.split(" ");

    for (const word of words) {
      if (!haystack.includes(word)) {
        return false;
      }
    }
    return true;
  }

  private filterCategory(search: string, categoryName: string): boolean {
    const words: string[] = [];

    let soFar = "";
    for (const char of search) {
      if (char === " " && soFar !== "final" && soFar !== "task") {
        words.push(soFar);
        soFar = "";
      } else {
        soFar += char;
      }
    }
    words.push(soFar);

    for (const word of words) {
      if (!categoryName.includes(word)) {
        return false;
      }
    }
    return true;
  }

  checkMatchesSearch(check: CheckBase, search: string) {
    search = search.toLowerCase();
    if ("approved".startsWith(search) && check.approved) {
      return true;
    }
    if ("unapproved".startsWith(search) && !check.approved) {
      return true;
    }
    if (this.containsWordsInAnyOrder(search, check.name.toLowerCase())) {
      return true;
    }
    if (check.creator && this.containsWordsInAnyOrder(search, check.creator.toLowerCase())) {
      return true;
    }
    if (this.filterCategory(search, check.category.name.toLowerCase())) {
      return true;
    }
    if (check.id.toString().indexOf(search) !== -1) {
      return true;
    }
    return false;
  }

  mounted() {
    this.checks
      .fetchAll()
      .then(() => this.setError(""))
      .catch(error => this.setError(extractErrorMessage(error)));

    this.$store.dispatch("checkcategory/fetchAll");
  }

  // ICONS
  private searchIcon = mdiMagnify;
  private filterCategoryIcon = mdiFilterPlusOutline;
}
