
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { User, Users, UserToAdd } from "@/components/users/Users";
import { extractErrorMessage } from "@/util/requests";
import ChangePassword from "@/components/users/ChangePassword.vue";
import CrudModifyActions from "@/components/crud/CrudModifyActions.vue";
import UserModificationComponent from "@/components/users/UserModificationComponent.vue";

@Component({
  components: {
    "change-password": ChangePassword,
    "crud-modify-actions": CrudModifyActions,
    "edit-user": UserModificationComponent
  }
})
export default class UserModifyActions extends Vue {
  private changeDialogOpened = false;
  private editDialogOpened = false;
  private requestPending = false;

  @Prop()
  private user!: User;

  @Prop()
  private users!: Users;

  get userCopy() {
    return new User(
      this.user.id,
      this.user.displayName,
      this.user.enabled,
      this.user.roles
    );
  }

  setEnabled(user: User, enabled: boolean) {
    this.handlePromise(this.users.setEnabled(user, enabled));
  }

  handlePromise(promise: Promise<any>) {
    promise
      .catch(error => this.$emit("error", extractErrorMessage(error)))
      .finally(() => (this.requestPending = false));
  }

  submitPasswordChange(password: string) {
    this.requestPending = true;
    this.changeDialogOpened = false;

    const userToAdd = new UserToAdd(
      this.user.id,
      this.user.displayName,
      this.user.roles,
      password
    );
    this.$emit("updated", userToAdd);

    (this.$refs["modify-actions"] as CrudModifyActions<
      User,
      UserToAdd
    >).updateElement(userToAdd);
  }

  submitUserChange(user: UserToAdd) {
    this.requestPending = true;
    this.editDialogOpened = false;

    (this.$refs["modify-actions"] as CrudModifyActions<
      User,
      UserToAdd
    >).updateElement(user);
  }

  blackhole() {}
}
