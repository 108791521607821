
import Vue from "vue";
import Component from "vue-class-component";
import { mdiHelpCircleOutline } from "@mdi/js";

@Component
export default class SubmitHelpDialog extends Vue {
  private ioHelpOpened: boolean = false;

  // ICONS
  private helpIcon = mdiHelpCircleOutline;
}
