
import Vue from "vue";
import { Users, UserToAdd } from "@/components/users/Users";
import { extractErrorMessage } from "@/util/requests";
import Component from "vue-class-component";
import UserModificationComponent from "@/components/users/UserModificationComponent.vue";
import UserModifyActions from "@/components/users/UserModifyActions.vue";
import { Watch } from "vue-property-decorator";
import { RootState } from "@/store/types";
import { mdiAccountPlus, mdiMagnify } from "@mdi/js";

@Component({
  components: {
    "new-user": UserModificationComponent,
    "user-modify-actions": UserModifyActions
  }
})
export default class UserList extends Vue {
  private search: string = "";
  private error: string = "";
  private users: Users = new Users();
  private footerProps = {
    itemsPerPageOptions: [4, 10, 20, 50, 100],
    pagination: {
      rowsPerPage: this.rowsPerPage
    }
  };
  private addDialogOpened = false;

  get rowsPerPage(): number {
    return (this.$store.state as RootState).miscsettings.itemsPerPage;
  }

  @Watch("footerProps.pagination.rowsPerPage", { deep: true })
  setRowsPerPage(rows: number) {
    this.$store.commit("miscsettings/setItemsPerPage", rows);
  }

  setError(error: string) {
    this.error = error;
  }

  addUser(user: UserToAdd) {
    this.addDialogOpened = false;
    (this.$refs["addUserModification"] as UserModificationComponent).clear();
    this.handlePromise(this.users.addItem(user));
  }

  mounted() {
    this.handlePromise(this.users.fetchAll());
  }

  handlePromise(promise: Promise<any>) {
    promise.catch(error => this.setError(extractErrorMessage(error)));
  }

  // ICONS
  private searchIcon = mdiMagnify;
  private addPersonIcon = mdiAccountPlus;
}
