
import Vue from "vue";
import Component from "vue-class-component";
import {
  CheckBase,
  CheckCollection,
  IOCheck
} from "@/components/checklist/CheckTypes";
import { Prop, Watch } from "vue-property-decorator";
import { UserState } from "@/store/types";
import { extractErrorMessage } from "@/util/requests";
import IOCheckComponent from "@/components/checksubmit/IOCheckComponent.vue";
import {
  mdiCheckCircleOutline,
  mdiCloseCircleOutline,
  mdiDelete,
  mdiPencil,
  mdiFormatText,
  mdiClose
} from "@mdi/js";

@Component({
  components: {
    "io-check-component": IOCheckComponent
  }
})
export default class ModifyActions extends Vue {
  private check: any = null;
  private ioCheck: IOCheck | null = null;
  private editDialogOpened = false;
  private checkClass: string | null = null;

  @Prop()
  private userState!: UserState;

  @Prop()
  private checks!: CheckCollection;

  @Prop()
  private myCheck!: CheckBase;

  @Prop({ default: "" })
  error!: string;

  get isIoCheck() {
    return this.myCheck.name;
  }

  get canUploadEdit() {
    return (
      this.check != null &&
      this.check.name != null &&
      this.check.name.length > 0
    );
  }

  @Watch("editDialogOpened")
  onEditDialogOpened() {
    if (!this.editDialogOpened) {
      return;
    }
    this.checks.fetchContent(this.myCheck).then(content => {
      let check = content.check as IOCheck;
      this.ioCheck = new IOCheck(
        check.input,
        check.output,
        check.name,
        check.files
      );
      this.checkClass = content.class;
    });
  }

  emitError(message: string) {
    this.$emit("error", message);
  }

  canModifyCheck(creator: string): boolean {
    if (this.userState.isAdmin()) {
      return true;
    }
    if (this.userState.isEditor()) {
      return true;
    }
    return this.userState.userName == creator;
  }

  canDeleteCheck(creator: string): boolean {
    if (this.userState.isAdmin()) {
      return true;
    }
    if (this.userState.isEditor()) {
      return true;
    }
    return this.userState.userName == creator;
  }

  canApprove(check: CheckBase) {
    return (
      (this.userState.isAdmin() || this.userState.isEditor()) && !check.approved
    );
  }

  canRevokeApprove(check: CheckBase) {
    return (
      (this.userState.isAdmin() || this.userState.isEditor()) && check.approved
    );
  }

  remove(check: CheckBase) {
    if (!confirm("Delete check: " + check.name + " (" + check.id + ")")) {
      return;
    }
    this.checks
      .deleteCheck(check)
      .then(() => this.emitError(""))
      .catch(error => this.emitError(extractErrorMessage(error)));
  }

  changeApproval(check: CheckBase, approved: boolean) {
    this.checks
      .setCheckApproval(check, approved)
      .then(() => this.emitError(""))
      .catch(error => this.emitError(extractErrorMessage(error)));
  }

  setCheck(check: IOCheck) {
    this.check = check;
  }

  changeCheck() {
    if (this.check == null) {
      return;
    }
    this.checks
      .updateIoCheck(this.check as IOCheck, this.myCheck.id, this.checkClass!)
      .then(() => {
        this.emitError("");
        this.editDialogOpened = false;
      })
      .catch(error => this.emitError(extractErrorMessage(error)));
  }

  // ICONS
  private deleteIcon = mdiDelete;
  private editIcon = mdiPencil;
  private approvedIcon = mdiCheckCircleOutline;
  private unapprovedIcon = mdiCloseCircleOutline;
  private viewRawIcon = mdiFormatText;
  private closeIcon = mdiClose;
}
