
import Vue from "vue";
import Component from "vue-class-component";
import ChangePassword from "@/components/users/ChangePassword.vue";
import Axios from "axios";
import { extractErrorMessage } from "@/util/requests";

@Component({
  components: {
    "change-password": ChangePassword
  }
})
export default class ChangeOwnPassword extends Vue {
  private message = "";
  private messageType = "error";
  private requestPending = false;

  submitChange(password: string) {
    this.requestPending = true;

    const data = new FormData();
    data.append("newPassword", password);

    Axios.post("/set-own-password", data)
      .then(() => {
        this.messageType = "success";
        this.message = "Successfully changed your password!";
      })
      .catch(error => {
        this.messageType = "error";
        this.message = extractErrorMessage(error);
      })
      .finally(() => {
        this.requestPending = false;
      });
  }
}
